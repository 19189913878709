import React, { Component } from 'react';
import {
    Alert, Button, Spinner, Table,
} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { PageStatus } from 'enums';
import {Show} from "../../Layout";
import CardHeader from "../Card/CardHeader";
import GridContainer from "../Grid/GridContainer";
import Card from "../Card/Card";
import moment from "moment/moment";
import {exportToExcel} from "../../Utils/ExportToExcel";
import {MessagesAPI, NewsLetterAPI} from "../../API";
import {Confirmation} from "../../Shared/Confirmation";
import Details from "./Details";
import ShowDataAccording2PageSize from '../ShowEntries/ShowEntries';
import PaginationNumbering from '../ShowEntries/Pagination';


const MODAL_TYPES = {
    NONE: 'NONE',
    CREATE: 'CREATE',
    UPDATE: 'UPDATE',
    DELETE: 'DELETE',
    DETAILS: 'DETAILS',
    BODY: 'BODY'
};

type State = {
    status: PageStatus,
    error: string | null,
    formType: string,
    body: string | null,
    data: any[],
    id?: string | null,
    filteredData: any[],
    filters: {
        queryType: any,
        email: any,
        queryStatus : any
    },
    pageSize : any,
    currentPageNumber : any,
    totalResponseData : any,
    totalResponsePages : any,
    loading: any,
};

export class Messages extends Component<any, any> {
    constructor(props) {
        super(props);
        this.state = {
            status: PageStatus.None,
            error: null,
            formType: MODAL_TYPES.NONE,
            data: [],
            id: null,
            body: null,
            filteredData: [],
            filters: {
                queryType: '',
                email: '',
                queryStatus : ''
            },
            pageSize : 10,
            currentPageNumber : 1,
            totalResponseData : '',
            totalResponsePages : '',
            loading: false,
        };
        this.fetchList = this.fetchList.bind(this);
    }

    componentDidMount() {
        this.fetchList()
    }

    fetchList(): Promise<void> {
        const {pageSize, currentPageNumber, filters} = this.state; 
        return Promise.resolve()
            .then(() => this.setState({ status: PageStatus.Loading }))
            .then(() => MessagesAPI.getAll(pageSize, currentPageNumber, filters))
            .then((countries) => {
                let responseData : any = countries;
                this.setState({ data: responseData.data, filteredData: responseData.data, totalResponseData: responseData.totalCount, totalResponsePages: responseData.totalPage, status: PageStatus.Loaded });
            })
            .catch((error) => {
                this.setState({ error: error.message, status: PageStatus.Error });
            });
    }

    isShowDetailModal(): boolean {
        return this.state.formType === MODAL_TYPES.DETAILS
            || this.state.formType === MODAL_TYPES.DELETE;
    }


    handleFilterChange = (e) => {
        const { name, value } = e.target;
        this.setState(
            (prevState) => ({
                filters: {
                    ...prevState.filters,
                    [name]: value,
                },
            }),
        );
    };

    applyFilters = () => {
        if (this.state.currentPageNumber == 1) { this.fetchList(); return; }
        this.setState({currentPageNumber : 1})

        // const { data } = this.state;
        // const { filters } = this.state;
        // const filteredData = data.filter((user) => {
        //     return Object.keys(filters).every((key) => {
        //         if (filters[key] === '') return true;
        //         if (key === 'email') {
        //             return String(user.user[key]).toLowerCase().includes(String(filters[key]).toLowerCase());
        //         }
        //         return String(user[key]).toLowerCase().includes(String(filters[key]).toLowerCase());
        //     });
        // });
        // this.setState({filteredData});
    }


    // clearFilter = () => {
    //     this.setState({ filters: {
    //             queryType: '',
    //             email: ''
    //         },
    //     })
    //     this.fetchList()
    // }

    clearFilter = async () => {
        await this.setState({ filters: {
            queryType: '',
            email: '',
            queryStatus : ''
        },
        pageSize : 10,
        currentPageNumber : 1,
        totalResponseData : '',
        totalResponsePages : ''
        })
        this.fetchList()
    }

    // handleExport(){
    //     let obj = this.state.filteredData.map((user) => {
    //         return {
    //             ...user,
    //             Mobile: user.user ? user.user.phoneNumber : '',
    //             Email: user.user ? user.user.email : ''
    //         }
    //     })
    //     exportToExcel(obj, 'Messages');
    // };

    async handleExport(){
        const {pageSize, currentPageNumber, filters} = this.state; 
        this.setState({ loading: true });
        try {
            const responseData : any = await MessagesAPI.getAll('All', 1, filters);
            exportToExcel(responseData.data, 'Messages');
        } catch (error) {
            console.error("Error exporting data:", error);
        }finally {
            this.setState({ loading: false });
        }
      };


    markAsResolved(id): Promise<void> {
        let values = { queryStatus: 'Resolved' }
        return Promise.resolve()
            .then(() => this.setState({ status: PageStatus.Loading }))
            .then(() => MessagesAPI.update(id, values))
            .then((countries) => {
                this.setState({ data: countries, filteredData: countries,  status: PageStatus.Loaded }, () => {
                    this.fetchList()
                });
            })
            .catch((error) => {
                this.setState({ error: error.message, status: PageStatus.Error });
            });
    }

    handlePageSizeChange = (newValue : any) => { this.setState({ pageSize: newValue, currentPageNumber: 1 }); };
    handlePageNumberChange = (newValue : any) => { this.setState({ currentPageNumber: newValue }); };
  
    componentDidUpdate(prevProps: {}, prevState: State) {
        if (prevState.pageSize !== this.state.pageSize || prevState.currentPageNumber !== this.state.currentPageNumber) { this.fetchList(); }
    };

    render() {
        // const { filteredData, filters } = this.state;
        const { filteredData, filters, pageSize, currentPageNumber, totalResponseData, totalResponsePages } = this.state;
        return (
            <>
                <GridContainer>
                    <div className='w-100 mb-3'>
                        <CardHeader color="primary">
                            <div className="d-flex align-items-center justify-content-between">
                                <h4>Messages</h4>
                            </div>
                        </CardHeader>
                    </div>
                </GridContainer>

                <div className="jumbotron bg-white p-3 border shadow-sm">
                    <div className='mb-3'>Filter</div>

                    <form>
                        <div className="row">
                            <div className="col">
                                <label htmlFor='gender'>Query Status</label>
                                <select
                                    style={{
                                        width: '100%',
                                        display: 'block',
                                        height: '40px',
                                        lineHeight: '1.5',
                                        color: '#495057',
                                        backgroundColor: '#fff',
                                        backgroundClip: 'padding-box',
                                        border: '1px solid #ced4da',
                                        borderRadius: '5px',
                                        transition:
                                            'border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out',
                                    }}
                                    name='queryStatus'
                                    id='queryStatus'
                                    required
                                    onChange={this.handleFilterChange}
                                    value={filters.queryStatus}
                                >
                                    <option value='select'>--Choose--</option>
                                    <option value='Pending'>Pending</option>
                                    <option value='Resolved'>Resolved</option>
                                </select>
                            </div>
                            <div className="col">
                                <label>Email</label>
                                <input type="text"
                                       className="form-control"
                                       placeholder="Enter..."
                                       name="email"
                                       value={filters.email}
                                       onChange={this.handleFilterChange}
                                />
                            </div>
                        </div>
                    </form>

                    <div className="jumbotron bg-white p-1 mt-2 shadow-sm">
                        <button type="button" className="btn btn-success" onClick={() => this.applyFilters()}>Filter Samples</button>
                        <button type="button" className="btn btn-info ml-1" onClick={() => this.handleExport()}>Export</button>
                        <button type="button" className="btn btn-danger ml-1" onClick={() => this.clearFilter()}>Clear Filter</button>
                    </div>


                </div>


                <div className="jumbotron bg-white p-3 border shadow-sm">
                    <Alert variant="danger" show={this.state.status === PageStatus.Error}>
                        {this.state.error}
                    </Alert>

                    <Show when={this.state.status === PageStatus.Loading}>
                        <div className="d-flex justify-content-center w-100 p-5">
                            <Spinner animation="border" variant="primary" />
                        </div>
                    </Show>

                    <Show when={this.state.status === PageStatus.Loaded}>

                        <Show when={!this.state.filteredData.length}>
                            <Alert variant="info" show={!this.state.filteredData.length}>
                                At the current moment data is not available, Click button for add.
                            </Alert>
                        </Show>

                        <Show when={this.state.formType === MODAL_TYPES.BODY}>
                            <Details
                                show={this.state.formType === MODAL_TYPES.BODY}
                                body={this.state.body}
                                onClose={() => this.setState({
                                    formType: MODAL_TYPES.NONE,
                                    body: null,
                                })}
                            />
                        </Show>

                        <Show when={!!this.state.filteredData.length}>
                            <div className='mb-2'>  
                                <ShowDataAccording2PageSize 
                                currentPageSize={pageSize} 
                                currentPage={currentPageNumber}
                                sendPageSize={this.handlePageSizeChange}
                                showTotalData={totalResponseData} 
                                sendLastPage={totalResponsePages}
                                />
                            </div>
                            <div style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', zIndex: '9999' }}>
                                {this.state.loading && (
                                    <img style={{ height: "415px", }}
                                        src="../loadernew.gif"
                                        alt="Loading..."
                                    />
                                )}
                            </div>
                            <Table responsive size="sm" bordered>
                                <thead>
                                <tr>
                                    <th>S.No</th>
                                    <th>Email</th>
                                    <th>Mobile</th>
                                    <th>UserId</th>
                                    <th>QueryType</th>
                                    <th>Query Status</th>
                                    <th>CreatedAt</th>
                                    <th>Click to view</th>
                                    <th>Status</th>
                                </tr>
                                </thead>

                                <tbody>
                                {
                                    this.state.filteredData.map((info, index) => (
                                        <tr key={info.id}>
                                            {/* <td>{index + 1}</td> */}
                                            <td>{((pageSize)*(currentPageNumber-1))+(index + 1)}</td>
                                            <td>{info.user ? info.user.email : '-'}</td>
                                            <td>{info.user ? info.user.phoneNumber : '-'}</td>
                                            <td>{info.userId}</td>
                                            <td>{info.queryType}</td>
                                            <td>{info.queryStatus}</td>
                                            <td>{moment(info.createdAt).format('MM/DD/YYYY HH:mm A')}</td>
                                            <td>
                                              <span
                                                  aria-label="button"
                                                  role="button"
                                                  tabIndex={0}
                                                  className="text-primary"
                                                  onKeyPress={() => null}
                                                  onClick={() => {
                                                      this.setState({
                                                          formType: MODAL_TYPES.BODY,
                                                          body: info.body,
                                                      });
                                                  }}
                                              >Click to view</span>
                                            </td>
                                            <td>
                                                {info.queryStatus === 'Pending' ? (
                                                        <Confirmation onAction={() =>  this.markAsResolved(info.id)} body="Are You Sure Want To Mark As Resolved?">
                                                            <button
                                                                type="button"
                                                                title="End the call"
                                                                className="btn btn-primary"
                                                            >
                                                                {/* <FontAwesomeIcon
                                                                    icon={['fas', 'resolving']}
                                                                    className="mr-2"
                                                                /> */}
                                                                Mark as Resolved
                                                            </button>
                                                        </Confirmation>
                                                ) : (
                                                    <button
                                                        type="button"
                                                        title="End the call"
                                                        disabled
                                                        className="btn btn-success"
                                                    >
                                                        <FontAwesomeIcon
                                                            icon={['fas', 'resolving']}
                                                            className="mr-2"
                                                        />
                                                        {info.queryStatus}
                                                    </button>
                                                )}
                                            </td>
                                        </tr>
                                    ))
                                }
                                </tbody>

                            </Table>
                            <div>
                                <PaginationNumbering 
                                currentPage={currentPageNumber} 
                                returnPagenumber={this.handlePageNumberChange}
                                showTotalData={totalResponseData} 
                                sendLastPage={totalResponsePages}
                                />
                            </div>
                        </Show>
                    </Show>
                </div>
            </>
        );
    }
}
