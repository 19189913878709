import * as _ from 'lodash';

import { api } from '../../axiosConfig';
// import { api } from '../axiosConfig';
import {faqUrl} from "../../Utils/urls";

export class FaqAPI {
    static getAll(
        limit : any,
        pagenumber: number,
        language: any,
        offset = 0,
    ): Promise<any[]> {
        return api.get(`${faqUrl}/getAll/${limit}/${pagenumber}/${language}`).then((res) => {            
            return _.get(res, 'data', []);
        });
    }
    
    static create(
        filters : any,
        offset = 0,
    ): Promise<any[]> {
        return api.post(`${faqUrl}/create`, filters).then((res) => {            
            return _.get(res, 'data', []);
        });
    }

    static getById(
        id : any,
        offset = 0,
    ): Promise<any[]> {
        return api.post(`${faqUrl}/getById`, id).then((res) => {            
            return _.get(res, 'data', []);
        });
    }

    static updatedetails(
        id : any,
        payload : any,
        offset = 0,
    ): Promise<any[]> {
        return api.post(`${faqUrl}/updatedetails/${id}`, payload).then((res) => {            
            return _.get(res, 'data', []);
        });
    }

    static delete(
        id : any,
        offset = 0,
    ): Promise<any[]> {
        return api.post(`${faqUrl}/delete/${id}`).then((res) => {            
            return _.get(res, 'data', []);
        });
    }    
}
