import React, { Component } from 'react';
import {
    Alert, Button, Spinner, Table,
} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { Form } from './Form';
import { DashboardDetails } from './DashboardDetails';

import { PageStatus } from 'enums';
import { Show } from "../../Layout";
import CardHeader from "../Card/CardHeader";
import GridContainer from "../Grid/GridContainer";
import Card from "../Card/Card";
import moment from "moment/moment";
import { SurveysAPI } from "../../API";
import { exportToExcel } from "../../Utils/ExportToExcel";
import ShowDataAccording2PageSize from '../ShowEntries/ShowEntries';
import PaginationNumbering from '../ShowEntries/Pagination';
// function filterByDaysRange(daysRange: any, currentDate: any, item: any) {
//     const itemDate: any = new Date(item.createdAt);
//     const timeDifference = currentDate - itemDate;
//     const daysDifference = Math.abs(timeDifference / (1000 * 3600 * 24))
//     return daysDifference <= daysRange;
// }


const MODAL_TYPES = {
    NONE: 'NONE',
    CREATE: 'CREATE',
    UPDATE: 'UPDATE',
    DELETE: 'DELETE',
    DETAILS: 'DETAILS',
};

type State = {
    status: PageStatus,
    error: string | null,
    formType: string,
    data: any[],
    id?: string | null,
    filteredData: any[],
    filters: {
        dateRange: any,
        name: '',
        client: '',
        publishDate: '',
        expiryDate: '',
        surveyName: '',
        surveyType: '',
        id: ''
    },
    pageSize: any,
    currentPageNumber: any,
    loading: any,
    survey_unique_id : any,
};

export class DashboardSurvey extends Component<any, any> {
    constructor(props) {
        super(props);
        this.state = {
            status: PageStatus.None,
            error: null,
            formType: MODAL_TYPES.NONE,
            data: [],
            id: null,
            filteredData: [],
            filters: {
                dateRange: '',
                name: '',
                client: '',
                publishDate: '',
                expiryDate: '',
                surveyName: '',
                surveyType: '',
                country: ''
            },
            pageSize: 10,
            currentPageNumber: 1,
            totalResponseData : '',
            totalResponsePages : '',
            loading: false,
            survey_unique_id : '',
        };
        this.fetchList = this.fetchList.bind(this);
    }

    componentDidMount() {
        this.fetchList()
    }

    fetchList(): Promise<void> {
        return Promise.resolve()
            .then(() => this.setState({ status: PageStatus.Loading }))
            .then(() => SurveysAPI.getAll(this.state.pageSize, this.state.currentPageNumber, this.state.filters))
            // .then(() => SurveysAPI.getAll(10000))
            .then((countries) => {
                let responseData: any = countries; 
                this.setState({ data: responseData.data, filteredData: responseData.data, totalResponseData: responseData.totalCount, totalResponsePages: responseData.totalPage, status: PageStatus.Loaded });
            })
            .catch((error) => {
                this.setState({ error: error.message, status: PageStatus.Error });
            });
    }

    isShowDetailModal(): boolean {
        return this.state.formType === MODAL_TYPES.DETAILS
            || this.state.formType === MODAL_TYPES.DELETE;
    }


    handleFilterChange = (e) => {
        const { name, value } = e.target;
        this.setState(
            (prevState) => ({
                filters: {
                    ...prevState.filters,
                    [name]: value,
                },
            }),
        );
    };

    applyFilters = () => {
        if (this.state.currentPageNumber == 1) { this.fetchList(); return; }
        this.setState({currentPageNumber : 1})
    };

    // applyFilters = () => {
    //     const { data } = this.state;
    //     const { filters } = this.state;
    //     if (filters.dateRange) {
    //         this.applyDateRange()
    //     } else {
    //         const filteredData = data.filter((user) => {
    //             return Object.keys(filters).every((key) => {
    //                 if (filters[key] === '') return true;
    //                 if (key === 'publishDate' || key === 'expiryDate') {
    //                     return new Date(user[key]).toDateString() === new Date(filters[key]).toDateString();
    //                 }
    //                 return String(user[key]).toLowerCase().includes(String(filters[key]).toLowerCase());
    //             });
    //         });
    //         this.setState({ filteredData });
    //     }
    // };


    // applyDateRange() {
    //     const currentDate = new Date();
    //     const { filters, data } = this.state;
    //     const selectedDaysRange = filters.dateRange;
    //     let filteredArray;
    //     if (selectedDaysRange === "30 Days") {
    //         filteredArray = data.filter(item => filterByDaysRange(30, currentDate, item));
    //         this.setState({ filteredData: filteredArray });
    //     } else if (selectedDaysRange === "60 Days") {
    //         filteredArray = data.filter(item => filterByDaysRange(60, currentDate, item));
    //         this.setState({ filteredData: filteredArray });
    //     } else if (selectedDaysRange === "90 Days") {
    //         filteredArray = data.filter(item => filterByDaysRange(90, currentDate, item));
    //         this.setState({ filteredData: filteredArray });
    //     } else if (selectedDaysRange === 'Last Year') {
    //         const lastYearStartDate = new Date();
    //         lastYearStartDate.setFullYear(lastYearStartDate.getFullYear() - 1);
    //         lastYearStartDate.setHours(0, 0, 0, 0);
    //         console.log('last---->', lastYearStartDate)
    //         filteredArray = data.filter(item => new Date(item.createdAt) >= lastYearStartDate);
    //         this.setState({ filteredData: filteredArray });
    //     } else {
    //         filteredArray = filters;
    //         this.setState({ filteredData: filteredArray });
    //     }
    // }
    clearFilter = async () => {
        await this.setState({
            filters: {
                client: '',
                publishDate: '',
                name: '',
                expiryDate: '',
                surveyName: '',
                surveyType: '',
                dateRange: '',
                country: '',
                id: ''
            },
            pageSize : 10,
            currentPageNumber : 1,
            totalResponseData : '',
            totalResponsePages : ''
        })
        this.fetchList();
    }

    async handleExport() {
        // exportToExcel(this.state.filteredData, 'surveys');
        const {pageSize, currentPageNumber, filters} = this.state; 
        this.setState({ loading: true });
        try {
            const recievedData = await SurveysAPI.getAll('All', currentPageNumber, filters);
            // const combinedData = [...rewardsData, ...this.state.filteredData];
            exportToExcel(recievedData.data, 'surveys');
        } catch (error) {
            console.error("Error exporting data:", error);
        }finally {
            this.setState({ loading: false });
        }
    };
    handlePageSizeChange = (newValue : any) => { this.setState({ pageSize: newValue, currentPageNumber: 1 }); };
    handlePageNumberChange = (newValue : any) => { this.setState({ currentPageNumber: newValue }); };

    componentDidUpdate(prevProps: {}, prevState: State) {
        if (prevState.pageSize !== this.state.pageSize || prevState.currentPageNumber !== this.state.currentPageNumber) { this.fetchList(); }
    }

    render() {
        const { filteredData, filters, pageSize, currentPageNumber, totalResponseData, totalResponsePages  } = this.state;
        return (
            <>
                <GridContainer>
                    <div className='w-100 mb-3'>
                        <CardHeader color="primary">
                            <div className="d-flex align-items-center justify-content-between">
                                <h4>Dashboard</h4>
                            </div>
                        </CardHeader>
                    </div>
                </GridContainer>

                <div className="jumbotron bg-white p-3 border shadow-sm">
                    <div className='mb-3'>Survey Details</div>
                    <form>
                        <div className="row">
                            <div className="col">
                                <label>Start Date</label>
                                <input
                                    type="date"
                                    className="form-control"
                                    placeholder="select date"
                                    name="publishDate"
                                    value={filters.publishDate}
                                    onChange={this.handleFilterChange}

                                />
                            </div>
                            <div className="col">
                                <label>Date Range</label>
                                <select
                                    style={{
                                        width: '100%',
                                        display: 'block',
                                        height: '40px',
                                        lineHeight: '1.5',
                                        color: '#495057',
                                        backgroundColor: '#fff',
                                        backgroundClip: 'padding-box',
                                        border: '1px solid #ced4da',
                                        borderRadius: '5px',
                                        transition:
                                            'border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out',
                                    }}
                                    name='dateRange'
                                    id='type'
                                    required
                                    value={filters.dateRange}
                                    onChange={this.handleFilterChange}
                                >
                                    <option value='' disabled>--Choose--</option>
                                    <option value='30 Days'>30 Days</option>
                                    <option value='60 Days'>60 Days</option>
                                    <option value='90 Days'>90 Days</option>
                                    <option value='Last Year'>Last Year</option>
                                </select>
                            </div>
                            <div className="col">
                                <label>End Date</label>
                                <input
                                    type="date"
                                    className="form-control"
                                    placeholder="select date"
                                    name="expiryDate"
                                    value={filters.expiryDate}
                                    onChange={this.handleFilterChange}

                                />
                            </div>
                            <div className="col">
                                <label>Type</label>
                                <select
                                    style={{
                                        width: '100%',
                                        display: 'block',
                                        height: '40px',
                                        lineHeight: '1.5',
                                        color: '#495057',
                                        backgroundColor: '#fff',
                                        backgroundClip: 'padding-box',
                                        border: '1px solid #ced4da',
                                        borderRadius: '5px',
                                        transition:
                                            'border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out',
                                    }}
                                    name='surveyType'
                                    id='type'
                                    required
                                    value={filters.surveyType}
                                    onChange={this.handleFilterChange}
                                >
                                    <option value='' disabled>--Choose--</option>
                                    {/* <option value='Open'>Open</option> */}
                                    <option value='Hold'>On Hold</option>
                                    <option value='Open'>Live</option>
                                    <option value='Wait'>Ids awaited </option>
                                    <option value='Close'>End</option>
                                </select>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col">
                                <label>Survey Name</label>
                                <input type="text"
                                    className="form-control"
                                    placeholder="Enter..."
                                    name="name"
                                    value={filters.name}
                                    onChange={this.handleFilterChange}
                                />
                            </div>
                            <div className="col">
                                <label>Client Name</label>
                                <input type="text" name="client" className="form-control" placeholder="Enter..." value={filters.client}
                                    onChange={this.handleFilterChange} />
                            </div>
                        </div>
                        {/* <div className="row">
                            <div className="col">
                                <label>Country</label>
                                <input type="text"
                                    className="form-control"
                                    placeholder="Enter..."
                                    name="country"
                                    value={filters.country}
                                    onChange={this.handleFilterChange}
                                />
                            </div>
                            <div className="col">
                                <label>ID</label>
                                <input type="text" name="id" className="form-control" placeholder="Enter..." value={filters.id}
                                    onChange={this.handleFilterChange} />
                            </div>
                        </div> */}
                    </form>

                    <div className="jumbotron bg-white p-1 mt-2 shadow-sm">
                        <button type="button" className="btn btn-success" onClick={() => this.applyFilters()}>Filter Surveys</button>
                        <button type="button" className="btn btn-info ml-1" onClick={() => this.handleExport()}>Export</button>
                        <button type="button" className="btn btn-danger ml-1" onClick={() => this.clearFilter()}>Clear Filter</button>
                    </div>


                </div>

                <div className="jumbotron bg-white p-3 border shadow-sm">
                    <Alert variant="danger" show={this.state.status === PageStatus.Error}>
                        {this.state.error}
                    </Alert>

                    <Show when={this.state.status === PageStatus.Loading}>
                        <div className="d-flex justify-content-center w-100 p-5">
                            <Spinner animation="border" variant="primary" />
                        </div>
                    </Show>

                    <Show when={this.state.status === PageStatus.Loaded}>
                        <Show when={this.state.formType === MODAL_TYPES.CREATE}>
                            <Form
                                show={this.state.formType === MODAL_TYPES.CREATE}

                                onClose={() => this.setState({
                                    formType: MODAL_TYPES.NONE,
                                })}
                                onSubmit={(id) => {
                                    this.fetchList();
                                    this.setState({
                                        formType: MODAL_TYPES.DETAILS, id: id,
                                    });
                                }}
                            />
                        </Show>

                        <Show when={!this.state.filteredData.length}>
                            <Alert variant="info" show={!this.state.filteredData.length}>
                                At the current moment data is not available, Click button for add.
                            </Alert>
                        </Show>

                        <Show when={!!this.state.filteredData.length}>
                            <Show when={this.isShowDetailModal()}>
                                <DashboardDetails
                                    id={this.state.id}

                                    unique_id={this.state.survey_unique_id}

                                    onClose={() => this.setState({
                                        formType: MODAL_TYPES.NONE,
                                        id: null,
                                    })}
                                    onUpdate={() => {
                                        this.setState({
                                            formType: MODAL_TYPES.UPDATE,
                                        });
                                    }}
                                    onDelete={(id) => {
                                        this.fetchList();
                                        this.setState({
                                            formType: MODAL_TYPES.NONE,
                                            id: null,
                                        });
                                    }}
                                />
                            </Show>

                            <Show when={this.state.formType === MODAL_TYPES.UPDATE}>
                                <Form
                                    show={this.state.formType === MODAL_TYPES.UPDATE}
                                    id={this.state.id}

                                    onClose={() => this.setState({
                                        formType: MODAL_TYPES.NONE,
                                        id: null,
                                    })}
                                    onSubmit={(id) => {
                                        this.fetchList();
                                        this.setState({
                                            formType: MODAL_TYPES.DETAILS, id: id,
                                        });
                                    }}
                                />
                            </Show>
                            <div className='mb-2'>
                                <ShowDataAccording2PageSize 
                                    currentPageSize={pageSize} 
                                    currentPage={currentPageNumber}
                                    sendPageSize={this.handlePageSizeChange}
                                    showTotalData={totalResponseData} 
                                    sendLastPage={totalResponsePages}
                                />                            
                            </div>
                            <div style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', zIndex: '9999' }}>
                                {this.state.loading && (
                                    <img style={{ height: "415px", }}
                                        src="../loadernew.gif"
                                        alt="Loading..."
                                    />
                                )}
                            </div>
                            <Table responsive size="sm" bordered>
                                <thead>
                                    <tr>
                                        <th>S.No</th>
                                        <th>Name</th>
                                        <th>Survey Id</th>
                                        <th>Survey Unique Id</th>
                                        <th>Client</th>
                                        <th>Active</th>
                                        <th>Survey Type</th>
                                        <th>CEGG Points</th>
                                        <th>Completes</th>
                                        <th>Pending</th>
                                        {/* add */}
                                        <th>Terminates</th>
                                        <th>Quotafull</th>
                                        <th>Quality Terminates</th>
                                        <th>Client IR </th>
                                        <th>IR %</th>
                                        <th>Median LOI (Overall)</th>
                                        {/* end */}
                                        <th>Start Date</th>
                                        <th>End Date</th>

                                    </tr>
                                </thead>

                                <tbody>
                                    {
                                        this.state.filteredData.map((survey, index) => (
                                            <tr key={survey.id}>
                                                <td>{((pageSize)*(currentPageNumber-1))+(index + 1)}</td>
                                                {/* <td>{index + 1}</td> */}
                                                <td>
                                                    <span
                                                        aria-label="button"
                                                        role="button"
                                                        tabIndex={0}
                                                        className="text-primary"
                                                        onKeyPress={() => null}
                                                        onClick={() => {
                                                            this.setState({
                                                                formType: MODAL_TYPES.DETAILS,
                                                                id: survey.id,
                                                                survey_unique_id: survey.uniqueid,
                                                            });
                                                        }}
                                                        dangerouslySetInnerHTML={{
                                                            __html: survey.name || 'Title',
                                                        }}
                                                    />
                                                </td>
                                                <td>{survey.id}</td>
                                                <td>{survey?.uniqueid}</td>
                                                <td>{survey.client}</td>
                                                <td>{survey.isActive === true ? 'Yes' : 'No'}</td>
                                                <td>{survey.surveyType == "Open" ? "Live" : survey.surveyType == "Hold" ? "On Hold" : survey.surveyType == "Wait" ? "Ids Awaited" : "End"}</td>
                                                <td>{survey.ceggPoints}</td>
                                                <td>{survey.completed_count}</td>
                                                <td>{survey.pending_count}</td>
                                                <td>{survey.terminated_count}</td>
                                                <td>{survey.over_quota_count}</td>
                                                <td>{survey.quality_terminated_count ?? 0}</td>
                                                <td>{survey.clientir ?? '0'}</td>

                                                <td>
                                                    {Number(survey.completed_count) + Number(survey.terminated_count) > 0
                                                        ? ((Number(survey.completed_count) / (Number(survey.completed_count) + Number(survey.terminated_count))) * 100).toFixed(2)
                                                        : '0.00'
                                                    }
                                                </td>


                                                <td>{(
                                                    (Number(survey.completed_count) || 0) +
                                                    (Number(survey.pending_count) || 0) +
                                                    (Number(survey.terminated_count) || 0) +
                                                    (Number(survey.over_quota_count) || 0) +
                                                    (Number(survey.quality_terminated_count) || 0)
                                                ) / 5}
                                                </td>

                                                <td>{moment(survey.publishDate).format('MM/DD/YYYY HH:mm A')}</td>
                                                <td>{moment(survey.expiryDate).format('MM/DD/YYYY HH:mm A')}</td>
                                            </tr>
                                        ))
                                    }
                                </tbody>

                            </Table>
                            <div>
                                <PaginationNumbering 
                                    currentPage={currentPageNumber} 
                                    returnPagenumber={this.handlePageNumberChange}
                                    showTotalData={totalResponseData} 
                                    sendLastPage={totalResponsePages}
                                />
                            </div>
                        </Show>
                    </Show>
                </div>
            </>
        );
    }
}
