import React, { Component } from 'react';

class ShowDataAccording2PageSize extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedPgValue: this.props.currentPageSize,
    };
  }

  handleChange = (event) => {
    const newValue = event.target.value;
    this.props.sendPageSize(newValue);
    this.setState({ selectedPgValue: newValue });
  };

  render() {
    const myNewPageNm = this.props.currentPage;
    const myNewPgSize = this.state.selectedPgValue;
    const myTotalData = this.props.showTotalData;
    const myLastPage = this.props.sendLastPage;
    
    return (
      <>
      <div className="d-flex justify-content-between align-items-center">
        <div className='btn-group align-items-center'>
            <h6>View</h6>
            <select
                data-bs-toggle="tooltip"
                data-bs-title="Show More"
                className="mx-2 form-control-sm btn-Primary"
                name="showMoreID"
                id="showMoreID"
                value={this.state.selectedPgValue}
                onChange={this.handleChange}
            >
                <option value="10">10</option>
                <option value="20">20</option>
                <option value="30">30</option>
                <option value="40">40</option>
                <option value="50">50</option>
                <option value="100">100</option>
            </select>
        </div>
        <div>
          <h6>
            <span>{myNewPageNm == 1 ? 1 : (myNewPgSize * (myNewPageNm - 1)) + 1}</span> to
            <span> {myNewPageNm == myLastPage ? myTotalData : myNewPgSize * myNewPageNm}</span> of
            <span> {myTotalData}</span>
          </h6>
        </div>
      </div>
      </>
    );
  }
}

export default ShowDataAccording2PageSize;
