import React, { Component } from 'react';
import {
  Alert, Button, Spinner, Table, Modal,
} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Form } from './Form';
import { Details } from './Details';
import { PageStatus } from 'enums';
import {Show} from "../../Layout";
import CardHeader from "../Card/CardHeader";
import GridContainer from "../Grid/GridContainer";
import Card from "../Card/Card";
import moment from "moment/moment";
import {exportToExcel} from "../../Utils/ExportToExcel";
import {NewsLetterAPI} from "../../API";
import {withRouter} from "react-router";
import {connect} from "react-redux";
import Body from "./Body";
import ShowDataAccording2PageSize from '../ShowEntries/ShowEntries';
import PaginationNumbering from '../ShowEntries/Pagination';
import ReactQuill from 'react-quill';

const MODAL_TYPES = {
  NONE: 'NONE',
  CREATE: 'CREATE',
  UPDATE: 'UPDATE',
  DELETE: 'DELETE',
  DETAILS: 'DETAILS',
  BODY: 'BODY'
};

type State = {
  status: PageStatus,
  error: string | null,
  formType: string,
  body: string | null,
  data: any[],
  id?: string | null,
  filteredData: any[],
  filters: {
    name: '',
  },
  pageSize : any,
  currentPageNumber : any,
  totalResponseData : any,
  totalResponsePages : any,
};

class NewsLetters extends Component<any, any> {
  constructor(props) {
    super(props);
    this.state = {
      status: PageStatus.None,
      error: null,
      formType: MODAL_TYPES.NONE,
      data: [],
      id: null,
      body: null,
      filteredData: [],
      // currentPage: 1,
      // pageSize: 100,
      filters: {
        name: '',
      },
      pageSize : 10,
      currentPageNumber : 1,
      totalResponseData : '',
      totalResponsePages : '',
      showTempleteModal : false,
      name : '',
      description : '',
    };
    this.fetchList = this.fetchList.bind(this);
  }

  componentDidMount() {
    this.fetchList()
  }

  fetchList(): Promise<void> {
    const {pageSize, currentPageNumber, filters} = this.state; 
    return Promise.resolve()
      .then(() => this.setState({ status: PageStatus.Loading }))
      .then(() => NewsLetterAPI.getAll(pageSize, currentPageNumber))
      .then((countries) => {
        let responseData : any = countries;
        this.setState({ data: responseData.data, filteredData: responseData.data, totalResponseData: responseData.totalCount, totalResponsePages: responseData.totalPage, status: PageStatus.Loaded });
      })
      .catch((error) => {
        this.setState({ error: error.message, status: PageStatus.Error });
      });
  }

  isShowDetailModal(): boolean {
    return this.state.formType === MODAL_TYPES.DETAILS
        || this.state.formType === MODAL_TYPES.DELETE;
  }


  handleFilterChange = (e) => {
    const { name, value } = e.target;
    this.setState(
        (prevState) => ({
          filters: {
            ...prevState.filters,
            [name]: value,
          },
        }),
    );
  };

  applyFilters = () => {
    const { data } = this.state;
    const { filters } = this.state;
      const filteredData = data.filter((user) => {
        return Object.keys(filters).every((key) => {
          if (filters[key] === '') return true;
          if (key === 'publishDate' || key === 'expiryDate') {
            return new Date(user[key]).toDateString() === new Date(filters[key]).toDateString();
          }
          return String(user[key]).toLowerCase().includes(String(filters[key]).toLowerCase());
        });
      });
      this.setState({filteredData});
    }


  clearFilter = () => {
    this.setState({ filters: {
        name: '',
      },
    })
    this.fetchList()
  }

  handleExport(){
    exportToExcel(this.state.filteredData, 'NewsLetters');
  };
  
  handlePageSizeChange = (newValue : any) => { this.setState({ pageSize: newValue, currentPageNumber: 1 }); };
  handlePageNumberChange = (newValue : any) => { this.setState({ currentPageNumber: newValue }); };

  componentDidUpdate(prevProps: {}, prevState: State) {
      if (prevState.pageSize !== this.state.pageSize || prevState.currentPageNumber !== this.state.currentPageNumber) { this.fetchList(); }
  };

  onCloseModalFunc = ()=>{
    this.setState({showTempleteModal : false, name : '', description : ''});
  }

  addNewsLetterFunc = (e)=>{
    e.preventDefault();
    const payloadData = {name : this.state.name, description : JSON.stringify(this.state.description)};
    return Promise.resolve()
    .then(() => this.setState({ }))
    .then(() => NewsLetterAPI.createtempleate(payloadData))
    .then((countries) => {
      let responseData : any = countries;
      alert("Templete added successfully!");
      this.setState({showTempleteModal : false, name : '', description : ''});
    })
    .catch((error) => {
      this.setState({ error: error.message, status: PageStatus.Error });
    }); 
  }

  render() {
    // const { filters } = this.state;
    // const { filteredData, currentPage, pageSize } = this.state;
    // const startIndex = (currentPage - 1) * pageSize;
    // const endIndex = Math.min(startIndex + pageSize, filteredData.length);
    // console.log('filteredData---->', filteredData)
    const { filteredData, filters, pageSize, currentPageNumber, totalResponseData, totalResponsePages } = this.state;
    return (
        <>
        <div>
          <Modal
              centered
              size="xl"
              backdrop="static"
              onHide={this.onCloseModalFunc}
              show={this.state.showTempleteModal}
              style={{ zIndex: 1201 }}
          >
            <Modal.Header closeButton>
              <Modal.Title>
                Add Templete
              </Modal.Title>
            </Modal.Header>
            <Modal.Body style={{ maxHeight: '78vh', overflow: 'auto' }}>
              {/* <Show when={this.state.status === PageStatus.Loading}>
                <div className="d-flex justify-content-center w-100 p-5">
                  <Spinner animation="border" variant="primary" />
                </div>
              </Show> */}

              <Alert variant="danger" show={this.state.status === PageStatus.Error}>
                {this.state.error}
              </Alert>

              <form onSubmit={this.addNewsLetterFunc}
              >
                <div className="row mt-2">
                  <div className="col">
                    <label htmlFor="name">Name</label>
                    <input
                        className="form-control"
                        name="name"
                        onChange={(e) => this.setState({ name: e.target.value })}
                        value={this.state.name}
                        placeholder="Enter here"
                    />
                  </div>
                </div>
                <div className="row mt-2">
                  <div className="col">
                    <label htmlFor="description">Description</label>
                    <ReactQuill
                        theme="snow" // other themes: 'bubble', 'core'
                        value={this.state.description}
                        onChange={(e) => this.setState({ description: e })}
                    />
                  </div>
                </div>
                <Alert variant="danger" show={!!this.state.error} className="mt-2">
                  {this.state.error}
                </Alert>
                <div className="d-flex align-items-center mt-2">
                  <button
                      type="submit"
                      // disabled={!this.state.body || !this.state.emails.length}
                      disabled={!this.state.description && !this.state.name}
                      className="btn btn-primary mr-3"
                  >
                    Submit
                  </button>
                  {/* <Show when={this.state.status === PageStatus.Submitting}>
                    <Spinner animation="border" variant="primary" />
                  </Show> */}
                </div>
              </form>
            </Modal.Body>
          </Modal>
        </div>
        <GridContainer>
            <div className='w-100 mb-3'>
              <CardHeader color="primary">
                <div className="d-flex align-items-center justify-content-between">
                <h4>Newsletters</h4>
                <div>
                  <Button
                      onClick={() => {
                        return this.setState({
                          formType: MODAL_TYPES.CREATE,
                        });
                      }}
                      variant="primary"
                      size="sm"
                      className="mx-1"
                  >
                    <FontAwesomeIcon icon={['fas', 'plus']} className="mr-2" />
                    Create
                  </Button>
                  <Button
                      onClick={() => {
                        return this.setState({
                          showTempleteModal: true,
                        });
                      }}
                      variant="primary"
                      size="sm"
                      className="mx-1"
                  >
                    <FontAwesomeIcon icon={['fas', 'plus']} className="mr-2" />
                    Add Templete
                  </Button>
                </div>
                </div>
              </CardHeader>
            </div>
        </GridContainer>

      <div className="jumbotron bg-white p-3 border shadow-sm">
        <Alert variant="danger" show={this.state.status === PageStatus.Error}>
          {this.state.error}
        </Alert>

        <Show when={this.state.status === PageStatus.Loading}>
          <div className="d-flex justify-content-center w-100 p-5">
            <Spinner animation="border" variant="primary" />
          </div>
        </Show>

        <Show when={this.state.status === PageStatus.Loaded}>
          <Show when={this.state.formType === MODAL_TYPES.CREATE}>
            <Form
              show={this.state.formType === MODAL_TYPES.CREATE}
              userId={this.props.userId}
              onClose={() => this.setState({
                formType: MODAL_TYPES.NONE,
              })}
              onSubmit={(id) => {
                this.fetchList();
                this.setState({
                  formType: MODAL_TYPES.DETAILS, id: id,
                });
              }}
            />
          </Show>

          <Show when={!this.state.filteredData.length}>
            <Alert variant="info" show={!this.state.filteredData.length}>
              At the current moment data is not available, Click button for add.
            </Alert>
          </Show>

          <Show when={!!this.state.filteredData.length}>
            <Show when={this.isShowDetailModal()}>
              <Details
                id={this.state.id}

                onClose={() => this.setState({
                  formType: MODAL_TYPES.NONE,
                  id: null,
                })}
                onUpdate={() => {
                  this.setState({
                    formType: MODAL_TYPES.UPDATE,
                  });
                }}
                onDelete={(id) => {
                  this.fetchList();
                  this.setState({
                    formType: MODAL_TYPES.NONE,
                    id: null,
                  });
                }}
              />
            </Show>

            <Show when={this.state.formType === MODAL_TYPES.UPDATE}>
              <Form
                show={this.state.formType === MODAL_TYPES.UPDATE}
                id={this.state.id}
                userId={this.props.userId}
                onClose={() => this.setState({
                  formType: MODAL_TYPES.NONE,
                  id: null,
                })}
                onSubmit={(id) => {
                  this.fetchList();
                  this.setState({
                    formType: MODAL_TYPES.DETAILS, id: id,
                  });
                }}
              />
            </Show>

            <Show when={this.state.formType === MODAL_TYPES.BODY}>
              <Body
                  show={this.state.formType === MODAL_TYPES.BODY}
                  body={this.state.body}
                  onClose={() => this.setState({
                    formType: MODAL_TYPES.NONE,
                    body: null,
                  })}
              />
            </Show>

            <div>
              <div className='mb-2'>  
                  <ShowDataAccording2PageSize 
                    currentPageSize={pageSize} 
                    currentPage={currentPageNumber}
                    sendPageSize={this.handlePageSizeChange}
                    showTotalData={totalResponseData} 
                    sendLastPage={totalResponsePages}
                  />
              </div>
              <Table responsive size="sm" bordered>
                <thead>
                <tr>
                  <th>S.No</th>
                  <th>Name</th>
                  <th>SendDate</th>
                  <th>Body</th>
                  <th>Status</th>
                  <th>CreatedAt</th>
                </tr>
                </thead>
                <tbody>
                {/* { filteredData.slice(startIndex, endIndex).map((info, index) => ( */}
                { filteredData.map((info, index) => (
                    <tr key={info.id}>
                      {/* <td>{filteredData.length - index - (currentPage - 1) * pageSize}</td> */}
                      <td>{((pageSize)*(currentPageNumber-1))+(index + 1)}</td>
                      <td>
                  <span
                      role="button"
                      tabIndex={0}
                      className="text-primary"
                      onClick={() => {
                        this.setState({
                          formType: MODAL_TYPES.DETAILS,
                          id: info.id,
                        });
                      }}
                  >
                    {info.name || 'Title'}
                  </span>
                      </td>
                      <td>{moment(info.sendDate).format('MM/DD/YYYY HH:mm A')}</td>
                      <td>
                  <span
                      role="button"
                      tabIndex={0}
                      className="text-primary"
                      onClick={() => {
                        this.setState({
                          formType: MODAL_TYPES.BODY,
                          body: info.body,
                        });
                      }}
                  >
                    Click to view
                  </span>
                      </td>
                      <td>{info.newsletterStatus}</td>
                      <td>{moment(info.createdAt).format('MM/DD/YYYY HH:mm A')}</td>
                    </tr>
                ))}
                </tbody>
              </Table>
              <div>
                <PaginationNumbering 
                  currentPage={currentPageNumber} 
                  returnPagenumber={this.handlePageNumberChange}
                  showTotalData={totalResponseData} 
                  sendLastPage={totalResponsePages}
                />
              </div>
              {/* <Button
                  onClick={() => this.setState(prevState => ({ currentPage: prevState.currentPage - 1 }))}
                  disabled={currentPage === 1}
              >
                Previous
              </Button>
              <Button
                  onClick={() => this.setState(prevState => ({ currentPage: prevState.currentPage + 1 }))}
                  disabled={endIndex >= filteredData.length}
              >
                Next
              </Button> */}
            </div>
        </Show>
        </Show>
      </div>
        </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    userId: state.adminUser.adminUser.userId,
  };
}

const NewsLettersWithRouter = withRouter(connect(
    mapStateToProps,
)(NewsLetters));

export { NewsLettersWithRouter as NewsLetters };
