export const dict = {
    en: {
      "My Requests": "My Requests",
      "Click to Redeem Points": "Click to Redeem Points",
      "Redeem Points": "Redeem Points",
      "Points": "Points",
      "Description": "Description",
      "Choose": "Choose",
      "PhonePe eGift Voucher": "PhonePe eGift Voucher",
      "Google Play Gift Code": "Google Play Gift Code",
      "Flpkart E-Gift Voucher": "Flpkart E-Gift Voucher",
      "Croma": "Croma",
      "AJIO E -Gift Card": "AJIO E -Gift Card",
      "Amazon UPI": "Amazon UPI",
      "Amazon Pay": "Amazon Pay",
      "Submit": "Submit",
      "Reset": "Reset",
      "Total Points Earned": "Total Points Earned",
      "Pending Redemptions": "Pending Redemptions",
      "Points Left": "Points Left",
      "Redemptions Request Summary": "Redemptions Request Summary",
      "S.No": "S.No",
      "Requested Date": "Requested Date",
      "Mode": "Mode",
      "Status": "Status",
      "User": "User",
      "Points Requested": "Points Requested",
      "Points Redeemed": "Points Redeemed",
      "Phone No/Data Card No/DTH No": "Phone No/Data Card No/DTH No",
      "Redeem Points Warning" : "At the current moment you can not Redeem Points, Your points must be greater than 100.",
      "Coupon Code": "Coupon Code",
      'No Redemptions': 'No Redemptions Request are available for you'
    },
    hi: {
      "My Requests": "मेरा अनुभव",
      "Click to Redeem Points": "पॉइंट को भुनाने के लिए क्लिक करें",
      "Redeem Points": "पॉइंट भुनाएं",
      "Points": "पॉइंट",
      "Description": "विवरण",
      "Choose": "चुनना",
      "PhonePe eGift Voucher": "Phonepe ई-उपहार वाउचर",
      "Google Play Gift Code": "Google Play उपहार कोड",
      "Flpkart E-Gift Voucher": "फ़्लपकार्ट ई-उपहार वाउचर",
      "Croma": "क्रोमा",
      "AJIO E -Gift Card": "अजियो ई-उपहार कार्ड",
      "Amazon UPI": "अमेज़ॅन यूपीआई",
      "Amazon Pay": "अमेज़ॅन पे",
      "Submit": "जमा करना",
      "Reset": "पुन्ह: सेट करे",
      "Total Points Earned": "कुल अर्जित पॉइंट",
      "Pending Redemptions": "लंबित मोचन",
      "Points Left": "पॉइंट छोड़ दिया",
      "Redemptions Request Summary": "मोचन अनुरोध सारांश",
      "S.No": "क्र.सं.",
      "Requested Date": "अनुरोधित दिनांक",
      "Mode": "प्रकार",
      "Status": "स्थिति",
      "User": "उपयोगकर्ता",
      "Points Requested": "अनुरोधित पॉइंट",
      "Points Redeemed": "भुनाए गए पॉइंट",
      "Phone No/Data Card No/DTH No": "फ़ोन नंबर/डेटा कार्ड नं/DTH NO",
      "Redeem Points Warning" : "इस समय आप पॉइंट भुना नहीं सकते, आपके पॉइंट 100 से अधिक होने चाहिए।",
      "Coupon Code": "कूपन कोड",
      "No Redemptions": "फिलहाल डेटा उपलब्ध नहीं है."

    }
};

export const redeemPointsDict = {
  en: {
    "Redeem Points": "Redeem Points",
    "Points": "Points",
    "Description": "Description",
    "Choose": "--Choose--",
    "Submit": "Submit",
    "Reset": "Reset"
  },
  hi: {
    "Redeem Points": "पॉइंट भुनाएं",
    "Points": "पॉइंट",
    "Description": "विवरण",
    "Choose": "--चुनें--",
    "Submit": "जमा करें",
    "Reset": "रीसेट करें"
  }
};
