export const AUTH_START = 'AUTH/AUTH_START';
export const AUTH_SUCCESS = 'AUTH/AUTH_SUCCESS';
export const AUTH_SUCCESS_LAST_STEP = 'AUTH/AUTH_SUCCESS_LAST_STEP'
export const AUTH_SUCCESS_MOBILE_STEP = 'AUTH/AUTH_SUCCESS_MOBILE_STEP'
export const AUTH_FAIL = 'AUTH/AUTH_FAIL';
export const AUTH_REGISTRATION_SUCCESS = 'AUTH/AUTH_REGISTRATION_SUCCESS'
export const AUTH_BASIC_PROFILE_SUCCESS = 'AUTH/AUTH_BASIC_PROFILE_SUCCESS'
export const LANGUAGE_CHANGE = 'AUTH/LANGUAGE_CHANGE'
export const LANGUAGE_CHANGE_NAVBAR = 'AUTH/LANGUAGE_CHANGE_NAVBAR'

