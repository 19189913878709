import * as _ from 'lodash';

import { api } from '../axiosConfig';
import {samplesURL} from "../Utils/urls";
import {surveysURL, surveyEmailSchedule} from "../Utils/urls";
export class SamplesAPI {
    static getAll(
        limit : any,
        currentPageNumber : number,
        filters : any,
        offset = 0,
    ): Promise<any[]> {
        return api.post(`${samplesURL}/getAll/${limit}/${currentPageNumber}`, filters).then((res) => {
            return _.get(res, 'data', []);
        });
    }

    static create(values: any): Promise<any> {
        return api.post(`${samplesURL}/create`, values)
            .then((res) => {
                return _.get(res, 'data.data', {});
            });
    }

    static getOne(
        id : string
    ): Promise<any[]> {
        return api.get(`${samplesURL}/getOne/${id}`, {
        }).then((res) => {
            return _.get(res, 'data.data', {});
        });
    }


    static getOneSampleUsers(
        id : string,
        page,
        limit
    ): Promise<any[]> {
        return api.get(`${samplesURL}/getOneSampleUsers/${id}?page=${page}&limit=${limit}`, {
        }).then((res) => {
            return _.get(res, 'data.data', {});
        });
    }

    static update(id: string, values: any): Promise<any> {
        return api.put(`${samplesURL}/update/${id}`, values)
            .then((res) => {
                return _.get(res, 'data.data', {});
            });
    }

    static deleteOne(id: string): Promise<any> {
        return api.delete(`${samplesURL}/delete/${id}`)
            .then((res) => {
                return _.get(res, 'data.data', {});
            });
    }


    static createQuestion(values: any): Promise<any> {
        return api.post(`${samplesURL}/create-questions`, values)
            .then((res) => {
                return _.get(res, 'data.data', {});
            });
    }

    static getOneQuestion(id: any): Promise<any> {
        return api.get(`${samplesURL}/get-SampleQuestion/${id}`)
            .then((res) => {
                return _.get(res, 'data.data', {});
            });
    }

    static getQuestions(secId: any): Promise<any> {
        return api.get(`${samplesURL}/get-SampleQuestions/${secId}`)
            .then((res) => {
                return _.get(res, 'data.data', []);
            });
    }

    static removeQuestion(id: any): Promise<any> {
        return api.delete(`${samplesURL}/remove-question/${id}`)
            .then((res) => {
                return _.get(res, 'data.data', {});
            });
    }

    static uploadUniqueLinks(values: any, id: string): Promise<any> {
        return api.post(`${samplesURL}/uploadUniqueLinks?id=${id}`, values)
            .then((res) => {
                return _.get(res, 'data.data', {});
            });
    }
    static Total_no_panelist(values: any, id: string): Promise<any> {
        return api.post(`${samplesURL}/noOfPanelistCount`)
            .then((res) => {
                return (res);
            });
    }
    static targetpanelistAPI(data: any): Promise<any> {
        return api.post(`${samplesURL}/noOfPanelistCount`, data)
            .then((res) => {
                return res;
            })
           
    }
    
    static getOneSampleUsersexport(
        id : string
    ): Promise<any[]> {
        return api.get(`${samplesURL}/getOneSampleUsersexport/${id}`, {
        }).then((res) => {
            return _.get(res, 'data', {});
        });
    }
    

}
